import React, { Component } from "react";
import axios from "axios";
import apiUrl from "../../config/config";
import classnames from "classnames";
import $ from "jquery";
import socketIOClient from "socket.io-client";
import { MDBDataTable } from "mdbreact";
import ProgressBtn from "../../components/common/ProgressButton";
import TopNav from "../../components/common/TopNav";
import SideBar from "../../components/common/SideBar";
import AddModalComplete from "../verifier/AddModalComplete";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import LinearProgress from "@material-ui/core/LinearProgress";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./worker.js";
const socket = socketIOClient("https://web.mybiocalculus.com:5000");

const customStyles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: "10px",
    position: "relative",
  },
  linearProgress: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.4,
    borderRadius: 4,
  },
};
class VerifierHome extends Component {
  constructor() {
    super();
    this.state = {
      menus: [{ title: "Patient List", url: "/verifier-home" }],
      isModalCompleteOpen: false,
      profileLink: "verifier-profile",
      patients: [],
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
      errors: {},
      userid: "",
      smoking: "",
      diabetes: "",
      height: "",
      weight: "",
      hospital: "",
      phone: "",
      patient_name: "",
      email_id: "",
      dob: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      thyroid_function: "",
      cardiac_surgery: "",
      doctors: [],
      doctor_id: "",
      doctor_name: "Choose",
      data: {},
      prog_data: 0,
      loading: false,
      socketNew: socket,
    };
    this.selectItem = this.selectItem.bind(this);
    this.getInfo = this.getInfo.bind(this);
    this.handleModalComplete = this.handleModalComplete.bind(this);

    this.preventDefault = this.preventDefault.bind(this);
  }

  componentDidMount() {
    const worker = new Worker();

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var newstat = this;
    /*socket.on('connect', function(){console.log('connected')});
    socket.on('connect_error', function(err){console.log(err)});
    socket.on('data', function(data){


      newstat.setState({"loading":true});
      
      console.log(newstat.state.loading)
    });
    socket.on('disconnect', function(){console.log('disconnected')});*/
    //   axios
    //        .get(apiUrl+'api/users/alldoctors')
    //        .then(res => {
    //          this.setState({ ['doctors']: res.data })
    //        })
    //        .catch(err => {
    //          this.setState({ ['doctors']: [] })
    //        });

    worker.postMessage({
      name: "alldoctors",
      url: apiUrl + "api/users/alldoctors",
    });
    worker.onmessage = function (event) {};
    worker.addEventListener("message", function (event) {
      newstat.setState({ ["doctors"]: event.data });
      //console.log(newstat.state.doctors);
    });
    axios
      .get(apiUrl + "api/users/getVerifierPatients")
      .then((res) => {
        this.setState({
          patients: res.data,
        });
        const data = {
          columns: [
            {
              label: "SL NO",
              field: "slno",
              sort: "asc",
              width: 50,
            },
            {
              label: "PATIENT ID",
              field: "patientId",
              sort: "asc",
              width: 100,
            },
            {
              label: "ADDED DATE",
              field: "added_date",
              sort: "asc",
              width: 100,
            },
            {
              label: "NAME",
              field: "name",
              sort: "asc",
              width: 150,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 200,
            },
            {
              label: "REPORT",
              field: "report",
              sort: "disabled",
              width: 150,
            },
          ],
          rows: [],
        };

        var onClick = this.selectItem.bind(this);
        var getInfo = this.getInfo.bind(this);

        var preventDefault = this.preventDefault.bind(this);
        var createdData = "";
        const options = [
          "RPeaks",
          "Classic",
          "Classical",
          "Classic Negative",
          "AI",
          "AIcodes",
        ];
        const defaultOption = options[0];
        res.data.map(function (patient, i) {
          var url = " ";
          if (patient.LastDatasetId != undefined) {
            if (
              patient.backup_status == 0 ||
              patient.backup_status == undefined
            ) {
              url =
                "/verifier-unanalysed/" +
                patient._id +
                "/" +
                patient.LastDatasetId;
            } else {
              url = " ";
            }
          }

          var anl_btn_txt = "teststart";
          var anl_btn_class = "btn-danger";
          var gen_btn_txt = "Not  Generated";
          var gen_btn_class = "btn-danger";
          var report_link = "#";
          var report_link_target = "";
          var report_status = patient.report_status;
          window.backup_status = patient.backup_status;
          if (patient.report_status == 1) {
            anl_btn_txt = "Analyzing...";
            anl_btn_class = "btn-success";
          }
          if (patient.report_status >= 2) {
            if (
              patient.backup_status == 0 ||
              patient.backup_status == undefined
            ) {
              url =
                "/verifier-chart/" + patient._id + "/" + patient.LastDatasetId;
            } else {
              url = " ";
            }
            anl_btn_txt = "test";
            anl_btn_class = "btn-success";
          }
          if (patient.report_status >= 4) {
            gen_btn_txt = "View Report";
            gen_btn_class = "btn-success";
            report_link_target = "_blank";
            report_link =
              "https://pdf.mybiocalculus.com/?userid=" +
              patient._id 
             
          }

          if (
            patient.backup_status == 0 ||
            patient.backup_status == undefined
          ) {
            anl_btn_txt = "TEST3";
          } else if (patient.backup_status == 1) {
            anl_btn_txt = "Retrieve";
          } else if (patient.backup_status == 2) {
            anl_btn_txt = "Retrieving";
          }
          createdData = patient.created_at.split("T");
          data.rows.push({
            slno: i + 1,
            patientId: patient.device_id,
            added_date: createdData[0],
            name: capitalizeFirstLetter(patient.name),
            action: (
              <React.Fragment>
                <div style={{ margin: "auto", width: "100%" }}>
                  <ProgressBtn
                    id={patient._id}
                    bc_stat={patient.backup_status}
                    onSelectModalComplete={this.handleModalComplete}
                    r_stat={patient.report_status}
                    socket={newstat.state.socketNew}
                  />
                  <button
                    type="button"
                    id={patient._id}
                    style={{ float: "left", marginRight: "20px" }}
                    onClick={getInfo}
                    className="btn btn-warning "
                    data-toggle="modal"
                    data-target="#editInfoModal"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    id={patient._id}
                    onClick={onClick}
                    style={{ float: "left" }}
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#myModalDHome"
                  >
                    Setting
                  </button>
                </div>
              </React.Fragment>
            ),
            report: (
              <React.Fragment>
                <a href={report_link} target={report_link_target}>
                  <button type="button" className={"btn " + gen_btn_class}>
                    {gen_btn_txt}
                  </button>
                </a>
                <input type="hidden" className="patientLink" value={url} />
              </React.Fragment>
            ),
          });
        }, this);
        this.setState({ data: data });
      })
      .catch((err) => {});

    $(document).ready(function () {
      $("#mbdTable").on("click", "tr td:nth-child(2)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
        }
      });
      $("#mbdTable").on("click", "tr td:nth-child(3)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
        }
      });
      $("#mbdTable").on("click", "tr td:nth-child(4)", function () {
        var url = $(this).parent().find(".patientLink").val();
        if (url != " ") {
          window.location.href = url;
        }
      });
    });
  }
  handleModalComplete(id) {
    console.log("id:", id);
    this.state.userid = id;
    this.setState({ isModalCompleteOpen: true });
  }
  onSubmit = (e) => {
    e.preventDefault();

    const settings = {
      userid: this.state.userid,
      nor_minhr: this.state.nor_minhr,
      nor_maxhr: this.state.nor_maxhr,
      qtcformula: this.state.qtcformula,
      qtcvalue_min: this.state.qtcvalue_min,
      qtcvaluemax: this.state.qtcvaluemax,
      qrswidth_min: this.state.qrswidth_min,
      qrswidth_max: this.state.qrswidth_max,
      qrsamp_min: this.state.qrsamp_min,
      qrsamp_max: this.state.qrsamp_max,
      stelevation: this.state.stelevation,
      pwidth_min: this.state.pwidth_min,
      pwidth_max: this.state.pwidth_max,
      pamp_min: this.state.pamp_min,
      pamp_max: this.state.pamp_max,
      stdepression: this.state.stdepression,
      twidth_min: this.state.twidth_min,
      twidth_max: this.state.twidth_max,
      tamp_min: this.state.tamp_min,
      tamp_max: this.state.tamp_max,
      printerval_min: this.state.printerval_min,
      printerval_max: this.state.printerval_max,
      tachyhr_lower: this.state.tachyhr_lower,
      tachyhr_upper: this.state.tachyhr_upper,
      bradyhr_lower: this.state.bradyhr_lower,
      bradyhr_upper: this.state.bradyhr_upper,
      pauselength: this.state.pauselength,
      graph_gen: this.state.graph_gen,
    };

    console.log(settings);
    axios
      .post(apiUrl + "api/patients/updateSettings", settings)
      .then((res) => {
        $("#closePopup").click();
        console.log("onclick");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSubmitInfo = (e) => {
    e.preventDefault();
    this.state.dob = document.getElementsByName("dob")[0].value;

    console.log(this.state.dob);

    const info = {
      name: this.state.patient_name,
      userid: this.state.userid,
      dob: this.state.dob,
      weight: this.state.weight,
      height: this.state.height,
      hospital: this.state.hospital,
      phone: this.state.phone,
      email: this.state.email_id,
      doctorid: this.state.doctor_id,
      smoking: this.state.smoking,
      diabetes: this.state.diabetes,
      af: this.state.af,
      hyper_tension: this.state.hyper_tension,
      hyper_lipidemia: this.state.hyper_lipidemia,
      indication: this.state.indication,
      cardiac_surgery: this.state.cardiac_surgery,
      thyroid: this.state.thyroid_function,
    };
    console.log(info);
    console.log(this.state.doctor_id);
    axios
      .post(apiUrl + "api/patients/updateInfo", info)
      .then((res) => {
        console.log(res.data);

        $(".closePopup").click();
        window.location.href = "/";
        //   var data = res.data;
        //   console.log("Name",res.data.name);
        //   this.setState({ patient_name: res.data.name });
        //   this.state.patient_name=res.data.name;
        //   console.log("Name",this.state.patient_name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);

    this.setState({ [e.target.name]: e.target.value });
  };

  preventDefault(e) {
    e.preventDefault();
  }

  getInfo(e) {
    this.setState({
      smoking: "",
      diabetes: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      cardiac_surgery: "",
      thyroid_function: "",
    });
    var userid = e.currentTarget.id;
    this.setState({ userid: userid });
    axios
      .get(apiUrl + "api/patients/getPatinetAdditionalInfo?userid=" + userid)
      .then((res) => {
        var data = res.data;
        console.log(res.data);
        console.log("Dataaa", data);
        if (res.data[0].smoking == true) {
          this.setState({ smoking: "yes" });
        } else if (res.data[0].smoking == false) {
          this.setState({ smoking: "no" });
        }
        if (res.data[0].af == true) {
          this.setState({ af: "yes" });
        } else if (res.data[0].af == false) {
          this.setState({ af: "no" });
        }
        if (res.data[0].hyper_tension == true) {
          this.setState({ hyper_tension: "yes" });
        } else if (res.data[0].hyper_tension == false) {
          this.setState({ hyper_tension: "no" });
        }
        if (res.data[0].hyper_lipidemia == true) {
          this.setState({ hyper_lipidemia: "yes" });
        } else if (res.data[0].hyper_lipidemia == false) {
          this.setState({ hyper_lipidemia: "no" });
        }
        if (res.data[0].cardiac_surgery == true) {
          this.setState({ cardiac_surgery: "yes" });
        } else if (res.data[0].cardiac_surgery == false) {
          this.setState({ cardiac_surgery: "no" });
        }
        this.setState({ thyroid_function: res.data[0].thyroid });
        this.setState({ diabetes: res.data[0].diabetes });
        this.setState({ indication: res.data[0].indication });
        this.setState({ height: res.data[0].height });
        this.setState({ weight: res.data[0].weight });
        this.setState({ hospital: res.data[0].hospital });
        this.setState({ phone: res.data[0].phone });
        this.setState({ patient_name: res.data[0].name });
        this.setState({ email_id: res.data[0].email });
        console.log("date1", res.data[0].dob);
        var dobt = new Date(Date.parse(res.data[0].dob));
        console.log("date2", dobt);
        dobt =
          dobt.getMonth() + 1 + "/" + dobt.getDate() + "/" + dobt.getFullYear();
        console.log("date3", dobt);
        this.setState({ dob: dobt });
        this.setState({ doctor_name: res.data[0].doctor_name });
        this.setState({ doctor_id: res.data[0].doctorid });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  selectItem(e) {
    var userid = e.currentTarget.id;
    this.setState({ userid: userid });
    this.setState({
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
    });
    axios
      .get(apiUrl + "api/patients/settings?userid=" + userid)
      .then((res) => {
        var data = res.data;
        this.setState(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (localStorage.jwtToken == undefined) {
      window.location.href = "/";
    }
    const { errors, patients } = this.state;
    /* const { errors,doctors } = this.state;*/

    let doctorsList =
      this.state.doctors.length > 0 &&
      this.state.doctors.map((item, i) => {
        return (
          <option
            key={i}
            value={item._id}
            selected={this.state.doctor_name == item.name}
          >
            {item.name}
          </option>
        );
      }, this);

    return (
      <div className="wrapper theme-6-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink} />
        <AddModalComplete
          userid={this.state.userid}
          isOpen={this.state.isModalCompleteOpen}
        />
        <SideBar menus={this.state.menus} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper"style={{marginTop:"30px"}}>
          <div className="container-fluid">
            <div id="myModalDHome" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      General Settings
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmit}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_minhr,
                              })}
                              name="nor_minhr"
                              required=""
                              id="nor_minhr"
                              onChange={this.onChange}
                              value={this.state.nor_minhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_maxhr,
                              })}
                              name="nor_maxhr"
                              required=""
                              id="nor_maxhr"
                              onChange={this.onChange}
                              value={this.state.nor_maxhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Formula
                            </label>
                            <select
                              name="qtcformula"
                              className={classnames("form-control", {
                                errors: errors.qtcformula,
                              })}
                              value={this.state.qtcformula}
                              onChange={this.onChange}
                            >
                              <option value="Bazett">Bazett </option>
                              <option value="Fridericia">Fridericia</option>
                              <option value="Framingham">Framingham</option>
                              <option value="Hodges">Hodges</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvalue_min,
                              })}
                              name="qtcvalue_min"
                              required=""
                              id="qtcvalue_min"
                              onChange={this.onChange}
                              value={this.state.qtcvalue_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvaluemax,
                              })}
                              name="qtcvaluemax"
                              required=""
                              id="qtcvaluemax"
                              onChange={this.onChange}
                              value={this.state.qtcvaluemax}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Min(ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_min,
                              })}
                              name="qrswidth_min"
                              required=""
                              id="qrswidth_min"
                              onChange={this.onChange}
                              value={this.state.qrswidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Max (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_max,
                              })}
                              name="qrswidth_max"
                              required=""
                              id="qrswidth_max"
                              onChange={this.onChange}
                              value={this.state.qrswidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_min,
                              })}
                              name="qrsamp_min"
                              required=""
                              id="qrsamp_min"
                              onChange={this.onChange}
                              value={this.state.qrsamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_max,
                              })}
                              name="qrsamp_max"
                              required=""
                              id="qrsamp_max"
                              onChange={this.onChange}
                              value={this.state.qrsamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Elevation (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stelevation,
                              })}
                              name="stelevation"
                              required=""
                              id="stelevation"
                              onChange={this.onChange}
                              value={this.state.stelevation}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Min (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_min,
                              })}
                              name="pwidth_min"
                              required=""
                              id="pwidth_min"
                              onChange={this.onChange}
                              value={this.state.pwidth_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_max,
                              })}
                              name="pwidth_max"
                              required=""
                              id="pwidth_max"
                              onChange={this.onChange}
                              value={this.state.pwidth_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_min,
                              })}
                              name="pamp_min"
                              required=""
                              id="pamp_min"
                              onChange={this.onChange}
                              value={this.state.pamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_max,
                              })}
                              name="pamp_max"
                              required=""
                              id="pamp_max"
                              onChange={this.onChange}
                              value={this.state.pamp_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Min (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_min,
                              })}
                              name="twidth_min"
                              required=""
                              id="twidth_min"
                              onChange={this.onChange}
                              value={this.state.twidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_max,
                              })}
                              name="twidth_max"
                              required=""
                              id="twidth_max"
                              onChange={this.onChange}
                              value={this.state.twidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_min,
                              })}
                              name="tamp_min"
                              required=""
                              id="tamp_min"
                              onChange={this.onChange}
                              value={this.state.tamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_max,
                              })}
                              name="tamp_max"
                              required=""
                              id="tamp_max"
                              onChange={this.onChange}
                              value={this.state.tamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Min{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_min,
                              })}
                              name="printerval_min"
                              required=""
                              id="printerval_min"
                              onChange={this.onChange}
                              value={this.state.printerval_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Max{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_max,
                              })}
                              name="printerval_max"
                              required=""
                              id="printerval_max"
                              onChange={this.onChange}
                              value={this.state.printerval_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_lower,
                              })}
                              name="tachyhr_lower"
                              required=""
                              id="tachyhr_lower"
                              onChange={this.onChange}
                              value={this.state.tachyhr_lower}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_upper,
                              })}
                              name="tachyhr_upper"
                              required=""
                              id="tachyhr_upper"
                              onChange={this.onChange}
                              value={this.state.tachyhr_upper}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_lower,
                              })}
                              name="bradyhr_lower"
                              required=""
                              id="bradyhr_lower"
                              onChange={this.onChange}
                              value={this.state.bradyhr_lower}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_upper,
                              })}
                              name="bradyhr_upper"
                              required=""
                              id="bradyhr_upper"
                              onChange={this.onChange}
                              value={this.state.bradyhr_upper}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Depression (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stdepression,
                              })}
                              name="stdepression"
                              required=""
                              id="stdepression"
                              onChange={this.onChange}
                              value={this.state.stdepression}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Pause Length (s){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pauselength,
                              })}
                              name="pauselength"
                              required=""
                              id="pauselength"
                              onChange={this.onChange}
                              value={this.state.pauselength}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Graphs To Be Generated(no:){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.graph_gen,
                              })}
                              name="graph_gen"
                              required=""
                              id="graph_gen"
                              onChange={this.onChange}
                              value={this.state.graph_gen}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-success"
                        style={{ color: "Black" }}
                      >
                        Submit
                      </button>
                      {
                        <button
                          id="closePopup"
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                          style={{ color: "Black" }}
                        >
                          Close
                        </button>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div id="editInfoModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      Edit Patient Information
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmitInfo}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onChange}
                              value={this.state.patient_name}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email_id"
                              onChange={this.onChange}
                              value={this.state.email_id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Doctor Name
                            </label>
                            <select
                              name="doctor_id"
                              className={classnames("form-control", {
                                errors: errors.doctor_id,
                              })}
                              onChange={this.onChange}
                              id="doctor_id"
                            >
                              <option value="Choose" style={{ color: "Black" }}>
                                Choose Doctor
                              </option>
                              {doctorsList}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              DOB
                            </label>
                            <div
                              className="input-group date"
                              id="datetimepicker1"
                            >
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  errors: errors.dob,
                                })}
                                onChange={this.onChange}
                                value={this.state.dob}
                                name="dob"
                              />
                              <span className="input-group-addon">
                                <span className="fa fa-calendar"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Height
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="height"
                              onChange={this.onChange}
                              value={this.state.height}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Weight
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="weight"
                              onChange={this.onChange}
                              value={this.state.weight}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Hospital Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="hospital"
                              onChange={this.onChange}
                              value={this.state.hospital}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              onChange={this.onChange}
                              value={this.state.phone}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Smoking Habits
                            </label>
                            <select
                              name="smoking"
                              className="form-control"
                              value={this.state.smoking}
                              onChange={this.onChange}
                            >
                              <option value="">Choose </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Diabetes
                            </label>
                            <select
                              name="diabetes"
                              className="form-control"
                              value={this.state.diabetes}
                              onChange={this.onChange}
                            >
                              <option value="Na">Na</option>
                              <option value="Type 1">Type 1</option>
                              <option value="Type 2">Type 2</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Atrial Fibrillation
                            </label>
                            <select
                              name="smoking"
                              className="form-control"
                              value={this.state.af}
                              onChange={this.onChange}
                            >
                              <option value="">Choose </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Hypertension
                            </label>
                            <select
                              name="diabetes"
                              className="form-control"
                              value={this.state.hyper_tension}
                              onChange={this.onChange}
                            >
                              <option value="">Choose </option>
                              <option value="yes">Yes</option>
                              <option value="no">NO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Hyperlipidemia
                            </label>
                            <select
                              name="hyper_lipidemia"
                              className="form-control"
                              value={this.state.hyper_lipidemia}
                              onChange={this.onChange}
                            >
                              <option value="">Choose </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Indication
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="indication"
                              onChange={this.onChange}
                              value={this.state.indication}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Thyroid Functions
                            </label>
                            <select
                              name="thyroid_function"
                              className="form-control"
                              value={this.state.thyroid_function}
                              onChange={this.onChange}
                            >
                              <option value="Normal">Normal</option>
                              <option value="Hyper">Hyper</option>
                              <option value="Hypo">Hypo</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Cardiac Surgery/Interventions
                            </label>
                            <select
                              name="cardiac_surgery"
                              className="form-control"
                              value={this.state.cardiac_surgery}
                              onChange={this.onChange}
                            >
                              <option value="yes">Yes</option>
                              <option value="no">NO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="row row-sm-offset">
                        <div className="col-md-6 multi-horizontal">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ color: "Black" }}
                          >
                            Submit
                          </button>
                        </div>
                        {
                          <div className="col-md-6 multi-horizontal text-left">
                            <button
                              id=""
                              type="button"
                              className="btn btn-default closePopup"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">Patient List</h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                    <MDBDataTable
  id="mbdTable"
  noBottomColumns
  data={this.state.data}
  entries={100} // Set default number of entries to 100
 
/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifierHome;